// import {makeAutoObservable} from 'mobx';
import uni_country from './t_uni_country';
export default class FilterStore{
    constructor(){
        this._mealTypes = [
            {id:1,name:'RO'},
            {id:2,name:'BB'},
            {id:3,name:'HB'},
            {id:4,name:'FB'},
            {id:5,name:'ALL'}
        ];

        this._villaTypes = [
            {id:0,name:'Any Room'},
            {id:1,name:'Water Villa'},
            {id:2,name:'Water Villa With Pool'},
            {id:3,name:'Beach Villa'},
            {id:4,name:'Beach Villa With Pool'}
        ];
        
        this._bedRooms = [
            {id:0,name:'ALL'},
            {id:1,name:'1'},
            {id:2,name:'2'},
            {id:3,name:'3'},
            {id:4,name:'4'},
            {id:5,name:'5'},
            {id:6,name:'6'},
            {id:7,name:'7'},
            {id:8,name:'8'},
            {id:9,name:'9'}
        ];

        this._childAges = [
            {value:1,label:'1'},
            {value:2,label:'2'},
            {value:3,label:'3'},
            {value:4,label:'4'},
            {value:5,label:'5'},
            {value:6,label:'6'},
            {value:7,label:'7'},
            {value:8,label:'8'},
            {value:9,label:'9'},
            {value:10,label:'10'},
            {value:11,label:'11'},
            {value:12,label:'12'},
            {value:13,label:'13'},
            {value:14,label:'14'},
            {value:15,label:'15'},
            {value:16,label:'16'},
            {value:17,label:'17'},
        ];

        this._stars = [
            {id:1,name:'2-STARS'},
            {id:2,name:'3-STARS'},
            {id:3,name:'4-STARS'},
            {id:4,name:'5-STARS'},
            {id:20,name:'GUEST HOUSE'},
        ];

        this._feature=[];
        this._resort=[];
        this._citizenships= uni_country();

   
        

        // makeAutoObservable(this);
    } 

    setResort(data){
        this._resort = data.sort((a,b)=>a.name.localeCompare(b.name) );
    }

    setFeature(data){
        this._feature = data.sort((a,b)=>a.name.localeCompare(b.name) );
    }

    setCitizenships(data){
        this._citizenships = data;
    }

    get bedRooms(){
        return this._bedRooms;
    }
    get mealTypes(){
        return this._mealTypes;
    }
    get villaTypes(){
        return this._villaTypes;
    }

    get childAges(){
        return this._childAges;
    }

    get stars(){
        return this._stars;
    }

    get resort(){
        return this._resort;
    }

    get feature(){
        return this._feature;
    }

    get citizenships(){
        return this._citizenships;
    }

}