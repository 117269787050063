import { $authHost,$host } from ".";
import jwt_decode from "jwt-decode";

export const registration = async (email, password)=>{
    const {data} = await $host.post('api/user/registration',{email, password, role:'ADMIN'});
    localStorage.setItem('token', data);
    console.log(data)
    return jwt_decode(data);
}

export const login = async (email, password)=>{
    // try{
    const res = await $host.post('api/user/login',{email, password,});
    console.log("res login" , res);
    localStorage.setItem('token', res.data);
    const user = jwt_decode(res.data);
    // console.log(user);
    return user;
    // }catch(e){
    //    // console.log(e);
    //     return e;
    // }
}

export const login1 = async (email, password)=>{
    const {data} = await $host.post('api/user/login',{email, password,});
    localStorage.setItem('token', data.token);
    const user = jwt_decode(data.token);
    console.log(user);
    return user;
}

export const check = async ()=>{
    const {data} = await $authHost.get('api/user/auth',{});
    localStorage.setItem('token', data.token);
    //  console.log(data, "data");
    //  console.log(data.token, "data.token")
    // return jwt_decode(data);
    const user = jwt_decode(data.token);
    console.log(user);
    return user;
}