export const getDateStr = (date = (new Date()), template = "YYYY-MM-DD")=>{
   
let strDate = template.replace("YYYY" , date.getFullYear())
    .replace("MM",(date.getMonth()+1).toString().padStart(2,'0'))
    .replace("DD",date.getDate().toString().padStart(2,'0'))
    .replace("hh",date.getHours().toString().padStart(2,'0'))
    .replace("mm",date.getMinutes().toString().padStart(2,'0'))
    ;
    return strDate;

}


export const   isValidDate = (dateStr)=>{
    return !isNaN(new Date(dateStr).getDate());
}

export const compareDates=(dateFrom,dateTo)=>{  
    if(!isValidDate(dateFrom)){
        alert("Date start is not valid!!!");
        return false;
    } 
    if(!isValidDate(dateTo)){
        alert("Date end is not valid!!!");
        return false;
    } 
           
   let d1 = new Date(dateFrom);
   let d2 = new Date(dateTo);
   if(d1 >= d2){
    alert("Dates are not valid!!!");
    return false;
   }

   return true;

}