import React from 'react';
// import HotelTable from './UI/hotelTable';
import  FilterableTable from 'react-filterable-table';





function CenterPanel({rooms ,}) {

   const renderUrl=(props)=>{  
    return (     
       <a href={props.value}  target={"_blank"}  rel="noreferrer" >Room Link</a>  
    );
   }

   const renderPrice=(props)=>{  
    return (     
       <span className='text-info'><ins><strong>{props.value}</strong></ins></span>  
    );
   }

   const renderFeature=(props)=>{  
    return (     
       <span className='text-success'><small>{props.value}</small></span>  
    );
   }

   const fields = [
    { name: 'hotelName', displayName: "Hotel", inputFilterable: true, exactFilterable: true,sortable: true },
    { name: 'star', displayName: "Stars", inputFilterable: true, exactFilterable: true, sortable: true },
    { name: 'roomName', displayName: "Room", inputFilterable: true, exactFilterable: true, sortable: true },
    { name: 'villaIds', displayName: "Villa", inputFilterable: true, exactFilterable: true, sortable: true },
    { name: 'CompanyMeal', displayName: "Meal", inputFilterable: true, exactFilterable: true, sortable: true },
    { name: 'transferName', displayName: "Transfer", inputFilterable: true, exactFilterable: true, sortable: true },
    { name: 'resort', displayName: "Resort", inputFilterable: true, exactFilterable: true, sortable: true },
    { name: 'features', displayName: "Features", inputFilterable: true, exactFilterable: true, sortable: true , render:renderFeature },
    { name: 'PriceTotal', displayName: "PriceTotal", inputFilterable: true, exactFilterable: true, sortable: true , render:renderPrice },
    { name: 'url', displayName: "Url", render:renderUrl},
   ];

  return (
    <div>

 <FilterableTable
	namespace="Rooms"
	initialSort="name"
	data={rooms}
	fields={fields}
	noRecordsMessage="There are no rooms"
	noFilteredRecordsMessage="No rooms match your filters!"
  pageSize={1000}
  pageSizes={[50,100,1000,10000]}
  loadingMessage="Wait..."
  tableClassName="overflow-auto"
  className="overflow-auto"
/>

    </div>
  );
}

export default CenterPanel;