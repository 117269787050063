import React , { useContext } from 'react';
import { useState } from 'react';
import { Col, Container, Form, Row , Button} from 'react-bootstrap';
// import { MultiSelect } from "react-multi-select-component";
import { getDateStr , compareDates } from '../utils/utils';
import  {Context} from '../index';
import MySelect from './UI/select/index';
import { usePromiseTracker } from "react-promise-tracker";
// import { fetchCitizenship} from '../http/dataAPI';


function SearchPanel({setSearchParam , searchData , load}) {
    let [dateFrom, setDateFrom] = useState(getDateStr());
    let [dateTo, setDateTo] = useState(getDateStr());
    let [adults, setAdults] = useState(2);
    let [country, setCountry] = useState(228);
    let [child, setChild] = useState("");
    // let [citizensips, setCitizensips] = useState([]);
    let [citizenship, setCitizenship] = useState(413);

    const {filter} = useContext(Context);

      //  useEffect(()=>{
      //  fetchCitizenship().then(data=>filter.setCitizenships(data));
      //  },[])
      // console.log("citizensips",filter);
      //  console.log("citizensips",filter.citizenships);

      const options = filter.citizenships.map(d => ({
        "value" : d.id,
        "name" : d.name
      }));

 

  const onSubmit=(e)=>{
    e.preventDefault();
    if(!compareDates(dateFrom,dateTo))
      return false;
      
   let search  = {};
   search.dateFrom = dateFrom;
   search.dateTo = dateTo;
   search.citizenship = citizenship;
   search.countryId = country;
   let ages = [];  
    for(let i=0;i<adults;i++)
      ages.push(25);


    search.ages = ages.join(',') + (child.length > 0 ? (","+ child) : "");
    // searchData(search); 
    setSearchParam(search);
     searchData(search);
  }



  const { promiseInProgress } = usePromiseTracker();

  return (
   <Container className='border border-secondary'>
    <Form className='d-flex justify-content-center mx-auto' onSubmit={onSubmit}>
      <Row className="align-items- mb-2">
      <Col>
      <Form.Group >
        <Form.Label>Country</Form.Label>
        <Form.Select aria-label="Country" defaultValue={"228"} 
           onChange={(e)=>setCountry(e.target.value)}>        
        <option value="228">Maldives</option>
        </Form.Select>
      </Form.Group>
      </Col>
        <Col>        
        <Form.Group >
        <Form.Label>Citizenship</Form.Label>
        <MySelect
          options={options}
          value={citizenship}
          onChange={setCitizenship} 
          className="form-control"   
          />
        </Form.Group>
        </Col>
        <Col>
        <Form.Group >
        <Form.Label>Date from</Form.Label>
        <Form.Control type='date' onChange={(e)=>setDateFrom(e.target.value)}
        />
      </Form.Group>
      </Col>
      <Col>
      <Form.Group >
        <Form.Label>Date to</Form.Label>
        <Form.Control type='date' onChange={(e)=>setDateTo(e.target.value)}
        />
      </Form.Group>
      </Col>

      {/* </Row>
      <Row className="me-2 mb-2 ml-2"> */}
      <Col>
      <Form.Group >
        <Form.Label>Adults</Form.Label>
        <Form.Select aria-label="adults" defaultValue={2} onChange={(e)=>setAdults(e.target.value)}>        
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
        <option value="9">9</option>
        </Form.Select>
      </Form.Group>
      </Col>
      <Col>
      <Form.Group >
        <Form.Label>Children</Form.Label>
        <Form.Control type="text" placeholder="Child by coma..."
             value={child}
             onChange={(e)=>setChild(e.target.value)}
         />
        {/* <MultiSelect
          options={filter.childAges}
          value={child}
          onChange={setChild}
          labelledBy="Select..."
          hasSelectAll={false}
        /> */}
      </Form.Group>
      </Col>
      
      {/* </Row>
      <Row 
      // className="me-2 mb-2 ml-2"
      > */}
      <Col  xs="auto"
      // className='d-flex justify-content-end align-item-buttom'
      >
      <Form.Group >
      {/* <Form.Label>&nbsp;</Form.Label> */}
       <Button variant="outline-success" type="submit" className="mt-4"
         disabled={promiseInProgress}
       >
       Search
      </Button>
      </Form.Group>  
      </Col>
      </Row>    
    </Form>
   </Container>
  );
}

export default SearchPanel;