import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { useContext } from 'react';
import { Context } from '../index';
import { ABOUT_ROUTE, HOTELS_ROUTE , LOGIN_ROUTE  } from '../utils/consts';
import Button from 'react-bootstrap/Button';
import {observer} from "mobx-react-lite";
import {useNavigate } from "react-router-dom";

const  NavBar = observer(()=>{
    const {user} = useContext(Context);  
     const navigate = useNavigate(); 
      const logOut = ()=>{
         user.setUser({});
         user.setIsAuth(false);
         localStorage.setItem('token', null);
         navigate(LOGIN_ROUTE);
      }
  return (
    <Navbar bg="dark" variant="dark">
    <Container> 
     <Nav.Link style={{color:'white'}} to={ABOUT_ROUTE}>WiottoSpeed</Nav.Link>     
      <Nav className="ml-auto">      
        <Nav.Link to={ABOUT_ROUTE}>ABOUT</Nav.Link>
        {user.isAuth && <Nav.Link href={HOTELS_ROUTE}>HOTELS</Nav.Link>}
        {
        user.isAuth ?
        <Button variant="outline-secondary" onClick={()=>logOut()}>Logout</Button>
        : <Button variant="outline-success" onClick={()=>navigate(LOGIN_ROUTE)}>Login</Button>        
         }
      </Nav>
    </Container>
  </Navbar>
  );
})

export default NavBar;