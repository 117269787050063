import React,{ useContext,useState} from 'react';
import {Container, Form, Card, Button, Row , NavLink , Col} from 'react-bootstrap';
import { useLocation,useNavigate } from 'react-router-dom';
import { LOGIN_ROUTE ,  REGISTRATION_ROUTE , HOTELS_ROUTE  } from '../utils/consts';
import {login, registration} from "../http/userApi";
import {observer} from "mobx-react-lite";
import { Context } from "..";
import { AxiosError } from 'axios';
const Auth = observer(()=>{
  const {user} = useContext(Context);
  const [email, setEmail] = useState('');
  const [error,setError] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const isLogin = location.pathname === LOGIN_ROUTE;

  const click = async()=>{

    try{
    let response;
    if(isLogin){
        response = await login(email, password);   
       }
    else{
       response = await registration(email, password);
    }
      // console.log("response Auth 33",response);
      user.setUser(response);
      user.setIsAuth(true);
      navigate(HOTELS_ROUTE);   
      setError('');
     
  }catch(e){
      setError(e.response.data.message);
  }
  } 

  return (
    <Container
     className='d-flex justify-content-center align-items-center'
     style = {{height:window.innerHeight - 54}}
     >
       <Card style={{width:'600px'}} className="p-5">
        <h2 className='m-auto'>Login</h2>
       <Form className='d-flex flex-column'>
          <Form.Control 
           className='mt-3'
           placeholder="login" type="text"
           value={email}  onChange={e=>setEmail(e.target.value)}
          />
           <Form.Control 
           className='mt-3'
           placeholder="password" type="password"
           value={password}  onChange={e=>setPassword(e.target.value)}
          />
{/*         
          <Button variant='outline-success' 
          className='mt-3 align-self-end'
          onClick={click}
          >Login</Button> */}

<Row className="d-flex justify-content-between mt-3 pl-3 pr-3"> 
                {isLogin ?
                <Col md={9}>
                  No account?<NavLink href={REGISTRATION_ROUTE}>SignUP</NavLink>
                </Col>
                :
                <Col>
                Have account?<NavLink href={LOGIN_ROUTE}>LogIn</NavLink>
               </Col>
                }
               <Col md={3}>
                <Button 
                 className='mt-3 align-self-end'
                  variant={'outline-success'} onClick={click}>
                 {isLogin ? 'Log In':'Sign UP'}
                </Button>
                </Col>
                </Row>
                <Row>
                  <Col style={{color:'red'}}>{error}</Col>
                  </Row>    
         
      </Form>
      </Card>
    </Container>
  );
});

export default Auth;