import React, { useContext } from 'react';
import {Routes, Route, Navigate} from 'react-router-dom'
import { authRoutes , publicRoutes } from '../routes';
import {  LOGIN_ROUTE  } from '../utils/consts';
import  {Context} from '../index';
function AppRouter() {
   
    const {user} = useContext(Context);
    console.log("user.isAuth  AppRuter 9 ", user.isAuth  );
    console.log("user ", user  );
  return (
    <Routes>
      {user.isAuth && authRoutes.map(({path, element}) =>
        <Route key={path} path={path} element={element}/>
      )}
      {publicRoutes.map(({path, element}) =>
      <Route key={path} path={path} element={element}/>
      )}
      <Route path='*' element={<Navigate to={LOGIN_ROUTE}/>} />
    </Routes>
  );
}

export default AppRouter;