import React ,{ useMemo, useState} from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import CenterPanel from '../components/CenterPanel';
import FilterPanel from '../components/FilterPanel';
import SearchPanel from '../components/SearchPanel';
import {fetchHotels} from '../http/dataAPI';
import { usePromiseTracker,trackPromise } from "react-promise-tracker";

function Hotels() {

  let[rooms, setRooms] = useState([]);
  // let[rooms, setRoomsAll] = useState([]);
  // let[filteredRooms, setFilteredRooms] = useState([]);
  let[filterMeal, setFilterMeal] = useState([]);
  let[filterVilla, setFilterVilla] = useState([]);
  let[filterStars, setFilterStars] = useState([]);
  let[filterResort, setFilterResort] = useState([]);
  let[filterBedRooms, setFilterBedRooms] = useState([]);
  let[filterFeature, setFilterFeature] = useState([]);

  
  let [searchParam, setSearchParam] = useState({});

  let [isLoading, setIsLoading] = useState(false);

  // let interval_id = null;
  

  const searchData = async(searchParam)=>{ 
    // console.log("searchParam", searchParam);
      setRooms([]);
    let partNum = 1;
    let emptyResponse = 0;
    
      const  interval_id = setInterval(async function () {
      const MAX_PART_NUM = window.$max_part_num ?? 70;
      //  setIsLoading(true) ;  
      trackPromise(
       fetchHotels(searchParam ,partNum++)
                        .then((room1)=>{
                          // setIsLoading(true) ; 
                          emptyResponse =  
                          emptyResponse + (!room1 || room1.length === 0);
                            //  console.log("room1.lenght_"+partNum,room1.count);
                            if(room1)                          
                                setRooms(rooms=>[...rooms,...room1.rooms]);
               
                          if(emptyResponse > 2 || partNum >= MAX_PART_NUM ) {
                            // console.log("emptyResponse_"+partNum,emptyResponse);
                                clearInterval(interval_id);
                                //  setIsLoading(false) ;               
                          } 
                        }).catch((error)=>{
                          console.log("error",error);
                        }));   
                        

          if( partNum === MAX_PART_NUM ) {
            clearInterval(interval_id);         
          } 
    }, 1000);  
    
  }
  
  
   const LoadingIndicator = props => {
    const { promiseInProgress } = usePromiseTracker();
        //  props.load(promiseInProgress);
       return (
        promiseInProgress && 
        <h2>Loading in progress ! </h2>
      );  
     }

  const  myFilteredRooms = useMemo(()=>{

  //  if(filterMeal.length > 0)
     return  [...rooms].filter(item=>(filterMeal.length ===0 || filterMeal.includes(item.mealPlanId)))
              .filter(item=>(filterStars.length === 0 || filterStars.includes(item.starId)))
              .filter(item=>(filterBedRooms.length === 0 || filterBedRooms.includes(item.bedroom)))
              .filter(item=>(filterResort.length === 0 || filterResort.includes(item.resortId)))
              
              .filter(item=>(filterVilla.length === 0 
                || [...(item.villaIds.split(','))]
                .filter(v=>filterVilla.includes(v)).length > 0))

                .filter(item=>(filterFeature.length === 0 
                  || 
                  filterFeature.every(f=>[...(item.featuresIds.split(','))]
                                          .find(fi=>fi == f)))                  
                  ); 


              // .filter(item=>(filterFeature.length === 0 || filterFeature.includes(item.featureIds)))
      // return [...rooms];
  },[rooms,filterMeal,filterStars,filterVilla,filterBedRooms
    , filterFeature,filterResort]);
  
  function changeFilter(e){
    
     const filterName =e.target.dataset.type;
     const itemId =e.target.id.replace(filterName+"_","");
     const ItemChecked =e.target.checked;

    //  console.log("filterName" ,filterName);
    //  console.log("itemId" ,itemId);
    //  console.log("ItemChecked" ,ItemChecked);

     if(filterName === "meal"){
        if(ItemChecked){
           setFilterMeal(prev => [...prev,itemId]);
        }
        else
           setFilterMeal(prev => prev.filter(e=>e !== itemId));
     }
     else if(filterName === "feature"){
      if(ItemChecked){
        setFilterFeature([...filterFeature,itemId]);
      }
      else{
         setFilterFeature(filterFeature.filter(e=>e !== itemId));
      }
    }
     else if(filterName === "bedroom"){
      if(ItemChecked){
        if(itemId === "0")
          setFilterBedRooms([]);
        else
          setFilterBedRooms([...filterBedRooms,itemId]);
      }
      else
         setFilterBedRooms(filterBedRooms.filter(e=>e !== itemId));
     }
     else if(filterName === "villa"){
      if(ItemChecked){
        if(itemId === "0")
          setFilterVilla([]);
        else
          setFilterVilla([...filterVilla,itemId]);
      }
      else
          setFilterVilla(filterVilla.filter(e=>e !== itemId));
     }
     else if(filterName === "stars"){
      if(ItemChecked){
        setFilterStars([...filterStars,itemId]);
      }
      else
         setFilterStars(filterStars.filter(e=>e !== itemId));
     }
     else if(filterName === "resort"){
      if(ItemChecked){
       
        setFilterResort([...filterResort,itemId]);
      }
      else
         setFilterResort(filterResort.filter(e=>e !== itemId));
     }

      

    //  console.log("filterBedRooms" ,filterBedRooms );
    //  console.log("filterFeature" ,filterFeature);
    //  console.log("filterMeal" ,filterMeal );
      console.log("filterResort" ,filterResort );
      console.log("filterStars" , filterStars );
    //  console.log("filterVilla" ,filterVilla );

     
      // setFilteredRooms((rooms)=>{
      //   //meal
      //   // let intersection = ar_hotel_villa.filter(x => arr_filter.includes(x));
      // return  rooms.filter(item=> filterMeal.includes( item.mealPlanId));

      // })

    // console.log("e.target" ,e.target );
    // //  const filterType = e.target.data.type;
    //  console.log("filterType" ,e.target.dataset.type);
    //  console.log("filterTypeaaaa" ,e.target.getAttribute("data-type"));
  
    // console.log("e.target" ,e.target.value )
    // console.log("e.target.dataTypes" ,e.target.dataTypes )
    // console.log("e.target.checked" ,e.target.checked);

    // const checked = e.target.checked;

    // setFilteredRooms(filteredRooms.filter())
  } 

  return (
   <Container className='border border-secondary'>
    <Row className='border border-secondary'>
        <Col className='border border-secondary'>
        <SearchPanel setSearchParam={setSearchParam} searchData={searchData} load={isLoading}/>
        </Col>
    </Row>
    <Row className='border border-secondary'>
        <Col md={2} sm={4} className='border border-secondary'>
          <FilterPanel  changeFilter={(e)=>changeFilter(e)}/>
        </Col>  
        <Col className='border border-secondary' md={10} sm={8}>
        <LoadingIndicator 
        //  load={setIsLoading}
        />
          <CenterPanel rooms={myFilteredRooms} style={{}}/>
        </Col>

    </Row>
   </Container>
  );
}

export default Hotels;