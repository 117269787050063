import Auth from "./pages/Auth"
import Hotels from "./pages/hotels";
import About from "./pages/About";
import  {LOGIN_ROUTE , HOTELS_ROUTE, ABOUT_ROUTE, REGISTRATION_ROUTE} from './utils/consts';


export const authRoutes = [
    {
        path:HOTELS_ROUTE,
        element: <Hotels/>
    }
]

export const publicRoutes = [
    {
        path:LOGIN_ROUTE,
        element: <Auth/>
    },
    {
        path:REGISTRATION_ROUTE,
        element: <Auth/>
    },
    {
        path:ABOUT_ROUTE,
        element: <About/>
    }
]