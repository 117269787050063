import React , { useContext , useEffect } from 'react';
import { fetchResort , fetchFeature } from '../http/dataAPI';
import  {Context} from '../index';
import CheckGroup from './UI/CheckGroup';

function FilterPanel({changeFilter}) {

 const {filter} = useContext(Context);
 const getFilterData = (type, value)=>{
 }

 useEffect(()=>{
   fetchResort().then(data=>filter.setResort(data));
   fetchFeature().then(data=>filter.setFeature(data));
    // fetchCitizenship().then(data=>filter.setCitizenships(data));
},[]);


  return (
    <div>
      <CheckGroup items={filter.mealTypes} filtername={"Meal type"} 
         filtertype={"meal"} getFilter={getFilterData} onChange={changeFilter}/>
         <hr/>
      <CheckGroup items={filter.bedRooms} filtername={"Bedrooms"} 
         filtertype={"bedroom"} getFilter={getFilterData} onChange={changeFilter}/>
     
        <hr/>
            <CheckGroup items={filter.villaTypes} filtername={"Villa"} 
            filtertype={"villa"} getFilter={getFilterData}  onChange={changeFilter}/>

         <hr/>
            <CheckGroup items={filter.stars} filtername={"Accommodation"} 
            filtertype={"stars"} getFilter={getFilterData} onChange={changeFilter}/>   
                     <hr/>
            <CheckGroup items={filter.resort} filtername={"Resort"} 
            filtertype={"resort"} getFilter={getFilterData}  onChange={changeFilter}/>   
                     <hr/>
            <CheckGroup items={filter.feature} filtername={"Features"} 
            filtertype={"feature"} getFilter={getFilterData} onChange={changeFilter}/>  
    </div>
  );
}

export default FilterPanel;