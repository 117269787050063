import { $authHost,$host } from ".";
import jwt_decode from "jwt-decode";



export const fetchResort = async ()=>{
    const {data} = await $authHost.get('api/resort');  
    return data;
}
 


export const fetchFeature = async ()=>{
    const {data} = await $authHost.get('api/feature');  
    return data;
}


export const fetchCitizenship = async ()=>{
    const {data} = await $authHost.get('api/citizenship');  
    return data;
}




export const fetchHotels =  async(param , partNum )=>{

    if(partNum > window.$max_part_num??70)
    return null;

    let searchParam = {...param ,  partNum}

    // searchParam.partNum = partNum;

    //  console.log("searchParam", searchParam);
   
    const {data} = await $authHost.post('api/hotel',{params:{searchParam}})
    // .then(()=>setIsLoading(false));
    ;

    return data;
}

// export const fetchOneDevice = async (id)=>{
//     const {data} = await $host.get('api/device/' + id);  
//     return data;
// }