import React, {useContext,useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './components/AppRouter';
import NavBar from './components/NavBar';
import {observer} from "mobx-react-lite";
import {Context} from "./index";
import {check} from "./http/userApi";
import {Spinner} from "react-bootstrap";
const App = observer(()=>{
  const {user} = useContext(Context)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
      check().then(data => {
          // console.log(data);
          user.setUser(data)
          user.setIsAuth(true)
      }).catch(error=>{
        console.log("check in App 19",error);
         user.setUser({})
        user.setIsAuth(false)
      }).finally(() => setLoading(false))
  }, [user])

  if (loading) {
      return <Spinner animation={"grow"}/>
  }

  return (
    <BrowserRouter>
      <NavBar/>
      <AppRouter/>
    </BrowserRouter>
  );
})

export default App;
