import React  from 'react';
import Form from 'react-bootstrap/Form';
function CheckGroup({items, filtername , filtertype , getFilter , onChange}){
 
    // let [values , setValues] = useState([]);


    const onChangeGroup =(e)=>{
      // console.log("onChangeGroup", e.target)
      // console.log("onChangeGroup_curr", e.target.current)
    }
    
 
    return (    
        <>
        <Form.Group>
        <Form.Label>{filtername}</Form.Label>
        <Form.Group  className='' onChange={onChangeGroup}>
        {      
        items && items.map((item)=>{  
        return <Form.Check onChange={onChange}  inline
        key={item.id} type="checkbox" label={item.name} 
         data-type={filtertype} id={filtertype+"_"+item.id}
        value={item.id}/> 
        } )  
        } 
        </Form.Group>  
       </Form.Group>  
        </>
    );
  }

export default CheckGroup;