import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import UserStore from './store/UserStore';
import FilterStore from './store/filterStore';

window.$max_part_num = 70;
export const Context = createContext(null);

// console.log("api", process.env.REACT_APP_API_URL);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Context.Provider value={{
      user:new UserStore(),
      filter:new FilterStore()
    }}>
    <App /> 
    </Context.Provider>
  </React.StrictMode>
);


